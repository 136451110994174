import { FooterWidgetWrapper } from "components/Footer";
import { Form } from "components/Forms";
import { buildWidgetInstanceId } from "features/widgets";
import { WIDGETS_REGISTRY } from "features/widgets/widgets.config";
import { Heading5, Paragraph } from "shared/components";

import { Banner } from "./Banner";
import styles from "./Footer001.module.scss";

export const Footer001 = ({ payload }) => {
  const title = payload?.title ?? "";
  const description = payload?.caption ?? "";
  const formType = payload?.formType ?? {};
  const banners = payload?.banners ?? [];
  const template = payload?.template ?? "light";

  const instanceId = buildWidgetInstanceId(
    "001",
    payload.id,
    WIDGETS_REGISTRY.FOOTER
  );

  return (
    <FooterWidgetWrapper className={styles[template]}>
      <div className={styles["text-wrap"]}>
        <div className={styles.text}>
          <Heading5 className={styles.title}>{title}</Heading5>
          <Paragraph className={styles.description} color="grey">
            {description}
          </Paragraph>
        </div>
      </div>

      <div className={styles.blocks}>
        {banners.map((banner) => (
          <Banner key={banner.id} banner={banner} />
        ))}
        <div className={styles.subscription}>
          <Form item={formType} instanceId={instanceId} className="modified" />
        </div>
      </div>
    </FooterWidgetWrapper>
  );
};

export default Footer001;
